@import "vendor.scss";
@import "input-range.scss";
@import "fancy-checkbox.scss";

// variables
$font-family-sans-serif: 'Open Sans', sans-serif;

$font-family-base: $font-family-sans-serif !default;
$font-family-title: $font-family-sans-serif !default;

$gray: #666666;
$gray2: #999999;
$blue: #6d9eeb;
$gwhite: #e9e9e9;
$gblack: #4a4a4a;


// $primary: #81acf2 !important;
//@debug: #dd7e6b !important;
//$primary: #f39c12 !important;
$primary: #EE675C !important;


html {
  scroll-behavior: smooth;
}

/* Hide native scrollbars in Chrome and Firefox */
* {
  
  scrollbar-color: #7b7b7b79 transparent;
}

//workaround..
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  border-radius: 22px;
  height: 44px;

}

.btn-reject {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  border-radius: 2px;
  margin-right: 17px;
  margin-top: 17px;
  color: white;
  
     

}

.btn-accept {
  background-color: #7dd494;
  border-color: #7dd494;
  border-radius: 2px;
  margin-right: 17px;
  margin-top: 17px;
  color: white;

  
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, div, span {
  font-family: $font-family-sans-serif !important;
  font-weight: 300;
  
}


h1 {
  font-size: 36px;
}

h2 {
  font-size: 36px;
}

h3 {
  // font-family: "Open Sans";
  font-size: 19px;
  color: #4a4a4a;
  font-weight: 800;
  

}

h4 {
  font-size: 22px;
  color: $primary;
}

h5 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 2rem;
}

h7 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 2rem;
}

$background2: #fafafa;


p {
  text-align: justify;
  font-size: 16px;
  // color: #999;
  color: rgb(95, 95, 95);
  line-height: 1.8;


  &.dark{
    color: rgb(95, 95, 95);
    font-size: 17px;
  }

  &.blog{
    color: rgb(95, 95, 95);
    font-size: 17px;
    line-height: 2;
    text-align: left;
  }
}

li{
  color: rgb(95, 95, 95);
  line-height: 2.2;
  font-size: 17px;
}

form {
  ::placeholder {
    opacity: 0.3 !important;
  }
}

.mySlides {display:none;}

@media (max-width: 991.98px) {
  .text-justify{
    // padding-left: 20px;
    // padding-right: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.gray-text {
  color: #999;
}

.hidden {
  display: none;
}

.anim {
  transition: 250ms opacity;
}
.anim-shown {
  opacity: 1;
}
.anim-hidden {
  opacity: 0;
}

.navbar {

  &.pre-scroll {
    // transform: top(-100px);
    top: -100px;
  }

  &.scrolled {
    position: fixed;
    top: 0px;
    // transform: top(0px);
    transition: all 250ms;

    .nav-link {
      //color: $gray2 !important;
      color: $gwhite !important;
      
    }

    .logo-text {
      color: $gwhite;
    }
  }

  &.navbar-light {
    //background: #2c3e51;
    background: #1d1d1d;
  }

  .logo-text {
    color: $gwhite;
    cursor: pointer;
  }

  .logo-text2 {
    color: $primary;
    cursor: pointer;
  }

  .nav-link {
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    // text-align: center;
    font-family: $font-family-sans-serif;
    //font-family: "open sans extrabold";
    color: white !important;
    padding-right: 0px;
    padding-left: 34px;

    &:hover {
      opacity: 0.75;
    }
  }

  

  .navbar-toggler {
    //color: rgb(102, 102, 102);
    color: $gwhite;
    padding-right: 0px;
    padding-left: 2px;
    margin-top: -3px;
    z-index: 2000;
    

    &:hover {
      //color: $gray;
      color: $gwhite !important;
    }
  }
}





.navbar-open .navbar-mobile {
  opacity: 0.99;
  backdrop-filter: blur(9px);
  transition: left 300ms;
  left: 0 !important;
  right: unset !important;
  background-color: #222222;
  width: 300px;
  
  .nav-link-gray {
    // color: $gray !important;
    color: #e9e9e9 !important;
    opacity: 0.5;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
 
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-mobile {
    background-color: #222222;
    transition: left 300ms;
    left: -300px;
    right: unset;
    width: 300px;
    z-index: -1;
   
  }

  .nav-navbar {
    margin-top: 40px;
  }

  .nav-link {
    padding-left: 16px !important;
  }

  .logo-display{
    display: none !important;
  }

 

 
 
  
}

.nav-match-left{
  margin-left: 8px;
}

.inline-logo {
  cursor: default;

  img {
    height: 28px;
    width: 28px;
    position: relative;
    top: 4px;
    display: inline-block;
    
  }
  .logo-text {
    position: relative;
    // top: -2px;
    font-size: 24px;
    
    font-weight: 500;
    padding-left: 9px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    top: -1px;

    b {
      // font-family: $font-family-sans-serif !important;
      font-weight: 800;
    }
  }

  @media (max-width: 991.98px) {
    .logo-text{
      padding-left: 8px;
      top: 0px;
    }
  }

}

.footer {
  .inline-logo {
    img {
      position: relative;
      top: -3px;
    }
    .logo-text {
      position: relative;
      top: 2px;
      color: $gray2;
    }
  }
  .nav-link {
    font-weight: 300;
    color: $gray2;
  }
}

.title-section {
  
  
  margin-bottom: 60px;
 
  @media (max-width: 991.98px) {
    margin-bottom: 30px;
  }
  
  // color: #999;
  color: #4a4a4a;
  
  
}

.wrapper {
  //background-color: green; /* for visualization purposes */
  text-align: center;
}

.drop {
  // background: rgba(255, 255, 255, 0.137);
  cursor: pointer;
  z-index: 3;
  color: white;
  font-size: 18px;
  font-weight: bold;
  // height:40%; 
  width: 40%;
  position: absolute;
  top: 50vh;
  left: 50vw;
  text-align: center;
  display: block;
  
  // margin-left: auto;
  // margin-right: auto;
  left: 30%;
  // border-radius: 5px;
  // border-style: dotted;
  // border-width: 1px;
  // border-color: rgb(192, 192, 192);
  
  
  
  backdrop-filter: blur(6px);
  transform: scale(1.0);
  -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s; 
 
}
.drop:hover {
  transform: scale(1.02);
  -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s; 
}


.cloud-icon{
  //background-image: url('../img/cloud.svg');
  height: 100px;
  width: 100px;
  zoom: 0.8;
  margin: auto;
 
}

.appstore-button{
  //background-image: url('../img/cloud.svg');
  // height: 100px;
  // width: 100px;
  // zoom: 0.8;
  margin: auto;

  background:url(../img/appstore-button.svg) no-repeat;
  cursor:pointer;
  border:none;
  width:121px;
  height:41px;
  margin-bottom: 14px;
 
}


.dropzone-content {
  //color: #fff;
  
  
  margin-top: 4%;
  transition: all .3s cubic-bezier(0,0,.2,1);
  //z-index: 1;
  cursor: pointer;
}

.mastering-dropzone-instruction {
  color: #fff;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 12px;
  margin-top: 16px;
}

.mastering-dropzone-help {
  color: #f1f4f6;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 100;
  opacity: .7;
}

.gradient {
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(134, 134, 134, 0.603) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(134, 134, 134, 0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(134, 134, 134, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  height:280px; width: 100%; position: absolute; bottom:0px;
}


.zoom {
  
  transition: transform .2s; /* Animation */
  
}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  cursor: pointer;
}

.myButton{
  background:url(../img/appstore-button.svg) no-repeat;
  cursor:pointer;
  border:none;
  width:121px;
  height:41px;
}

myButton:hover {
   cursor: pointer;
}

.section {
  
  padding-top: 5rem;

  @media (max-width: 991.98px) {
    padding-top: 3rem;
    background-position: bottom 0px;
  }
  
  &-home {
    top: -50px;
    // background-image: url('../img/guitar-app-lessons-tuner-metronme-chords.jpeg'); 
    background-image: url('../img/guitarapp.jpg'); 
     
    padding-bottom: 0rem;
    height: 110vh;

    .title-section {
      //padding-top: 565px;
      color: white;
      // margin-top: 40vh;
      margin-top: 76vh;
    }
  }
  &-b {
    background: $background2;
  }
}

@media (min-width: 991.98px) {
  .card {
    padding: 1.25rem 0;
  }
}

#section-services {
  .card {
    h5 {
      text-align: center;
      //@at-rootcolor: $gray2;
      color: $primary;
    }

    .info-text, .learn-more {
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: justify;
      
      @media (min-width: 991.98px) {
        padding: 10px;
      }
      
    }

    


    .learn-more{
      color: $primary;

    }

    .image {
      margin-top : 0px !important;

      img {
        width: 130px;
      }
    }
  }
}

#section-technology {
  .diagram-container {
    padding: 40px;
  }
}

#section-team {
  h5 {
    margin-bottom: 0.5rem;
  }

  small {
    font-size: 17px;
    color: #999;
  }
}

#section-demos {
  .card {
    //margin-bottom: 10px;
    font-size: 15px;
    color: $gray2;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    border-color: $gray;
    border-top: 1px !important;
    border-left: 1px !important;
    border-right: 1px !important;

    .card-body{
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 0px;

    }

    .card-alt-text {
      font-size: 15px;
      font-family: "Open Sans";
      font-weight: 500;
      color: white;
      padding-left: 10px;
      padding-top: 150px;

    }

    .container{
      padding: 0px;
      
    }

    .text {
      font-weight: 700;
      color: $gray2;
      position: relative;
      top: 2px;
    }

    .progress {
      width: 100%;
      position: relative;
      top: -3px;
    }

    .vrx-container {
      text-align: right;
      //padding-left: 10px;
      color: $primary;
      position: relative;
      top: -2px;

      span {
        // transform: scale(1.25);
        display: inline-block;
        //padding-right: 17.5px;
        //@media (max-width: 480px){padding-right: 5px;}
        
      }
      
      .switch {
        position: relative;
        top: 5px;
        margin-left: 10px;
        transition: 0.3s all;

        &:hover {
          opacity: 0.85;
          transform: scale(1.05);
        }
      }
    }
    .player-container {
      color: $primary;
      position: relative;
      top: 2px;

      .play, .pause {
        transform: scale(1.75);
        transition: 0.3s all;
        cursor: pointer;

        &:hover {
          opacity: 0.85;
          transform: scale(2);
        }
      }
    }
  }

  .title-section-video {
    margin-top: 6rem;
  }
}

.video-thumbnail {
  //position: relative;
  //display: inline-block;
  cursor: pointer;
  //margin: 40px;

  &:before {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\25b6";
    font-family: FontAwesome;
    font-size: 40px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    -webkit-transform: translate(-48%, -48%);
    transform: translate(-48%, -48%);
    
    transition: 0.3s all;

    color: #eee;
  }
}

#section-faq {
  .accordion {
    .card {
      padding: 0;
    }
  }
}

//#section-contact {}
#section-privacy {


    display: none;
 
}

.blueBold {
  color:#007bff;
  font-weight: 500;
}

@media (min-width: 991.98px) {
    .yt-blog{
      width: 1600;
      height: 900;
    }
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-top: 20px;
  // margin-left: 5%;
  // margin-right: 5%;
  
 
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  
  width:100%;
  height: 100%;
  
}

.vid-size{
  width: 100%;
  height: auto; /* Let the height adjust based on the aspect ratio */
  height: calc(100vw * 9 / 16); /* Maintain 16:9 aspect ratio based on viewport width */
}

// @media only screen and (min-width: 768px){
//   .vid-size{  
//     width: 640px;
//     height: 360px;
//   }
// }

/* Styles for screens up to 320px width */
@media (max-width: 320px) {
  .vid-size{  
    width: 288px;
    height: 162px;
  }
}

/* Styles for screens between 321px and 480px width */
@media (min-width: 321px) and (max-width: 480px) {
  .vid-size{  
    width: 320px;
    height: 180px;
  }
}

/* Styles for screens between 481px and 600px width */
@media (min-width: 481px) and (max-width: 600px) {
  .vid-size{  
    width: 480px;
    height: 270px;
  }
}

/* Styles for screens between 601px and 768px width */
@media (min-width: 601px) and (max-width: 768px) {
  .vid-size{  
    width: 512px;
    height: 288px;
  }
}

/* Styles for screens above 768px width */
@media (min-width: 769px) {
  .vid-size{  
    width: 640px;
    height: 360px;
  }
}






.brand-left{
  padding-left: 4px;
}

.social-spacing{
  margin-right: 40px;
}

.popup-border{
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
}

.code {
  font-family: courier;
  color: rgb(114, 230, 148);
  background-color: #3d3d3d;
  padding: 2px;
  font-size: 16px;
  margin: auto;
  width: 50%;
  border-radius: 5px;
  margin-bottom: 40px;
  
  padding: 16px;
  display: block;
}

.big-button{
  display: inline-block;
  font-family: 'Open Sans';
  // width: 140px;
  height: 40px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ec5d51;
  text-align: center;

  line-height: 40px;
  font-weight: bold;
  font-size: 16px;
  // cursor: pointer;
  
  border:0px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
}

.chip{
  display: inline-block;
  font-family: 'Open Sans';
  // width: 140px;
  height: 36px;
  color: white;
  border-radius: 50px;
  // background-color: #6495ed;
  background-color: #4288e2;
 
  text-align: center;

  line-height: 36px;
  font-weight: 600;
  font-size: 13px;
  // cursor: pointer;
  
  border:0px;
  border-style: solid;
  // border-color: #cacaca;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  
  margin-bottom: 6px;

}

.home-chip{
  display: inline-block;
  font-family: 'Open Sans';
  // width: 140px;
  height: 40px;
  color: white;
  border-radius: 50px;
  // background-color: #6495ed;
  background-color: #4288e2;
 
  text-align: center;

  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  // cursor: pointer;
  
  border:0px;
  border-style: solid;
  // border-color: #cacaca;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  width:240px;
  margin-bottom: 6px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 8px;

}

.donate-button{
  display: inline-block;
  font-family: 'Open Sans';
  // width: 140px;
  height: 40px;
  color:#003087;
  border-radius: 50px;
  // background-color: #6495ed;
  background-color: #ffd140;
  ;
 
  text-align: center;

  line-height: 40px;
  font-weight: 700;
  font-size: 16px;
  // cursor: pointer;
  
  border:0px;
  border-style: solid;
  // border-color: #cacaca;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  width:200px;
  margin-bottom: 6px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 8px;

}

.mini-chip{
  display: inline-block;
  font-family: 'Open Sans';
  // width: 140px;
  height: 28px;
  color: white;
  border-radius: 50px;
  // background-color: #6495ed;
  background-color: #f9f9f924;
 
  text-align: center;

  line-height: 28px;
  font-weight: 600;
  font-size: 13px;
  // cursor: pointer;
  
  border:0px;
  border-style: solid;
  // border-color: #cacaca;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  // width:200px;
  margin-bottom: 6px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 0px;

}

.desk-side-nav{
    color: rgb(233 233 233 / 50%);
    font-weight: 400;
    margin-bottom: 6.2px;
    font-size: 15.5px;

    &:hover {
      color: #e9e9e9 !important; 
    }
}

.side-nav{

    display: block; 
    position: absolute; 
    background-color: #222222; 
    // height: 100%; 
    overflow-y: auto;
    width: 239px; 
    padding-top: 79px; 
    top: 0px; 
    padding-left: 19px; 
    border-style: solid; 
    border-right-color: #323232; 
    border-width: thin;
    z-index: 100;

  
}

@media (max-width: 991.98px) {
  .side-nav-display{
    display: none !important;
  }
  .blog-text-offset{
    margin-left: auto; 
    width: auto; 
    
  }
}

@media (min-width: 991.98px) {

  .blog-text-offset{
    margin-left: 295px; 
    width: auto; 
    padding-right: 75px;
  }
}

.selected{
  font-weight: 700; 
  color: #e9e9e9
}

.side-nav-divider{
    top: -4px;
    left: -20px;
    width: 239px;
    position: relative;
    margin-top: 0px; 
    margin-bottom: 0px; 
    border-top-color: #323232;

}

.search-icon{

    padding: 0px 16px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: white;

}

.search-box{

    position: relative;
    border-radius: 4px;
    background-color: rgba(157, 157, 157, 0.15);
    margin-left: -4px;
    width: 100%;
    color: #e9e9e9;
    border-style: none;
    height: 37.56px;
    width: 207px;
    outline: none;
    padding: 8px 8px 8px calc(1em + 32px);
    font-size: 15px;
    font-weight: 500;

}

.search-item{

    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding: 8px 0px;
    

}

::placeholder {
  color: #979797;
  opacity: 1; /* Firefox */
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}